import Vue from 'vue'
import { wrapFunctional } from './utils'

const components = {
  Footer: () => import('../../components/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c)),
  Header: () => import('../../components/Header.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c)),
  LayoutWrap: () => import('../../components/LayoutWrap.vue' /* webpackChunkName: "components/layout-wrap" */).then(c => wrapFunctional(c.default || c)),
  Logo: () => import('../../components/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c)),
  PageWrap: () => import('../../components/PageWrap.vue' /* webpackChunkName: "components/page-wrap" */).then(c => wrapFunctional(c.default || c)),
  Sidebar: () => import('../../components/Sidebar.vue' /* webpackChunkName: "components/sidebar" */).then(c => wrapFunctional(c.default || c)),
  Article: () => import('../../components/article/Article.vue' /* webpackChunkName: "components/article" */).then(c => wrapFunctional(c.default || c)),
  ArticleContent1: () => import('../../components/article/ArticleContent1.vue' /* webpackChunkName: "components/article-content1" */).then(c => wrapFunctional(c.default || c)),
  ArticleContent2: () => import('../../components/article/ArticleContent2.vue' /* webpackChunkName: "components/article-content2" */).then(c => wrapFunctional(c.default || c)),
  ArticleContent3: () => import('../../components/article/ArticleContent3.vue' /* webpackChunkName: "components/article-content3" */).then(c => wrapFunctional(c.default || c)),
  ArticleContent4: () => import('../../components/article/ArticleContent4.vue' /* webpackChunkName: "components/article-content4" */).then(c => wrapFunctional(c.default || c)),
  ArticleContent5: () => import('../../components/article/ArticleContent5.vue' /* webpackChunkName: "components/article-content5" */).then(c => wrapFunctional(c.default || c)),
  ArticleContent6: () => import('../../components/article/ArticleContent6.vue' /* webpackChunkName: "components/article-content6" */).then(c => wrapFunctional(c.default || c)),
  ArticleContent7: () => import('../../components/article/ArticleContent7.vue' /* webpackChunkName: "components/article-content7" */).then(c => wrapFunctional(c.default || c)),
  Button: () => import('../../components/button/Button.vue' /* webpackChunkName: "components/button" */).then(c => wrapFunctional(c.default || c)),
  Form: () => import('../../components/form/Form.vue' /* webpackChunkName: "components/form" */).then(c => wrapFunctional(c.default || c)),
  FormInput: () => import('../../components/form/Input.vue' /* webpackChunkName: "components/form-input" */).then(c => wrapFunctional(c.default || c)),
  Heading: () => import('../../components/heading/Heading.vue' /* webpackChunkName: "components/heading" */).then(c => wrapFunctional(c.default || c)),
  Banner: () => import('../../components/banner/Banner.vue' /* webpackChunkName: "components/banner" */).then(c => wrapFunctional(c.default || c)),
  Breadcrumb: () => import('../../components/breadcrumb/Breadcrumb.vue' /* webpackChunkName: "components/breadcrumb" */).then(c => wrapFunctional(c.default || c)),
  BreadcrumbItem: () => import('../../components/breadcrumb/BreadcrumbItem.vue' /* webpackChunkName: "components/breadcrumb-item" */).then(c => wrapFunctional(c.default || c)),
  Link: () => import('../../components/link/Link.vue' /* webpackChunkName: "components/link" */).then(c => wrapFunctional(c.default || c)),
  ListAdviser: () => import('../../components/list/ListAdviser.vue' /* webpackChunkName: "components/list-adviser" */).then(c => wrapFunctional(c.default || c)),
  ListAdviserItem: () => import('../../components/list/ListAdviserItem.vue' /* webpackChunkName: "components/list-adviser-item" */).then(c => wrapFunctional(c.default || c)),
  ListAlbum: () => import('../../components/list/ListAlbum.vue' /* webpackChunkName: "components/list-album" */).then(c => wrapFunctional(c.default || c)),
  ListArticle: () => import('../../components/list/ListArticle.vue' /* webpackChunkName: "components/list-article" */).then(c => wrapFunctional(c.default || c)),
  ListArticleItem: () => import('../../components/list/ListArticleItem.vue' /* webpackChunkName: "components/list-article-item" */).then(c => wrapFunctional(c.default || c)),
  ListArticleTitle: () => import('../../components/list/ListArticleTitle.vue' /* webpackChunkName: "components/list-article-title" */).then(c => wrapFunctional(c.default || c)),
  ListExample: () => import('../../components/list/ListExample.vue' /* webpackChunkName: "components/list-example" */).then(c => wrapFunctional(c.default || c)),
  ListGuest: () => import('../../components/list/ListGuest.vue' /* webpackChunkName: "components/list-guest" */).then(c => wrapFunctional(c.default || c)),
  ListGuestItem: () => import('../../components/list/ListGuestItem.vue' /* webpackChunkName: "components/list-guest-item" */).then(c => wrapFunctional(c.default || c)),
  ListHeader: () => import('../../components/list/ListHeader.vue' /* webpackChunkName: "components/list-header" */).then(c => wrapFunctional(c.default || c)),
  ListHeaderTitle: () => import('../../components/list/ListHeaderTitle.vue' /* webpackChunkName: "components/list-header-title" */).then(c => wrapFunctional(c.default || c)),
  ListLinkMore: () => import('../../components/list/ListLinkMore.vue' /* webpackChunkName: "components/list-link-more" */).then(c => wrapFunctional(c.default || c)),
  ListNews: () => import('../../components/list/ListNews.vue' /* webpackChunkName: "components/list-news" */).then(c => wrapFunctional(c.default || c)),
  ListNewsItem: () => import('../../components/list/ListNewsItem.vue' /* webpackChunkName: "components/list-news-item" */).then(c => wrapFunctional(c.default || c)),
  ListNote: () => import('../../components/list/ListNote.vue' /* webpackChunkName: "components/list-note" */).then(c => wrapFunctional(c.default || c)),
  ListRelate: () => import('../../components/list/ListRelate.vue' /* webpackChunkName: "components/list-relate" */).then(c => wrapFunctional(c.default || c)),
  ListSchoolItem: () => import('../../components/list/ListSchoolItem.vue' /* webpackChunkName: "components/list-school-item" */).then(c => wrapFunctional(c.default || c)),
  ListSerial: () => import('../../components/list/ListSerial.vue' /* webpackChunkName: "components/list-serial" */).then(c => wrapFunctional(c.default || c)),
  ListSerialDetail: () => import('../../components/list/ListSerialDetail.vue' /* webpackChunkName: "components/list-serial-detail" */).then(c => wrapFunctional(c.default || c)),
  ListSideAlbum: () => import('../../components/list/ListSideAlbum.vue' /* webpackChunkName: "components/list-side-album" */).then(c => wrapFunctional(c.default || c)),
  Pagination: () => import('../../components/pagination/Pagination.vue' /* webpackChunkName: "components/pagination" */).then(c => wrapFunctional(c.default || c)),
  ShareBar: () => import('../../components/share/ShareBar.vue' /* webpackChunkName: "components/share-bar" */).then(c => wrapFunctional(c.default || c)),
  ShareButtons: () => import('../../components/share/ShareButtons.vue' /* webpackChunkName: "components/share-buttons" */).then(c => wrapFunctional(c.default || c)),
  Tag: () => import('../../components/tag/Tag.vue' /* webpackChunkName: "components/tag" */).then(c => wrapFunctional(c.default || c)),
  TagList: () => import('../../components/tag/TagList.vue' /* webpackChunkName: "components/tag-list" */).then(c => wrapFunctional(c.default || c))
}

for (const name in components) {
  Vue.component(name, components[name])
  Vue.component('Lazy' + name, components[name])
}
