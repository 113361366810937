import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _a23d3e32 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _3cccd867 = () => interopDefault(import('../pages/adviser/index.vue' /* webpackChunkName: "pages/adviser/index" */))
const _7e89977a = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _20ffe115 = () => interopDefault(import('../pages/cookie-policy.vue' /* webpackChunkName: "pages/cookie-policy" */))
const _4cca86a6 = () => interopDefault(import('../pages/emailchange.vue' /* webpackChunkName: "pages/emailchange" */))
const _125f59e2 = () => interopDefault(import('../pages/error.vue' /* webpackChunkName: "pages/error" */))
const _22067347 = () => interopDefault(import('../pages/example/index.vue' /* webpackChunkName: "pages/example/index" */))
const _5b5a2782 = () => interopDefault(import('../pages/letter/index.vue' /* webpackChunkName: "pages/letter/index" */))
const _cf7e427a = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _bae3f07e = () => interopDefault(import('../pages/logoff.vue' /* webpackChunkName: "pages/logoff" */))
const _f7aa447a = () => interopDefault(import('../pages/logoffdone.vue' /* webpackChunkName: "pages/logoffdone" */))
const _2bfa7bde = () => interopDefault(import('../pages/passwordforget.vue' /* webpackChunkName: "pages/passwordforget" */))
const _5a164b6e = () => interopDefault(import('../pages/passwordreset.vue' /* webpackChunkName: "pages/passwordreset" */))
const _5494ba3c = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _b957c18e = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _06af0d8a = () => interopDefault(import('../pages/registerdone.vue' /* webpackChunkName: "pages/registerdone" */))
const _7ffb2212 = () => interopDefault(import('../pages/rule.vue' /* webpackChunkName: "pages/rule" */))
const _4b783fe4 = () => interopDefault(import('../pages/seminar/index.vue' /* webpackChunkName: "pages/seminar/index" */))
const _5dd05c8d = () => interopDefault(import('../pages/serial/index.vue' /* webpackChunkName: "pages/serial/index" */))
const _25fde8af = () => interopDefault(import('../pages/tags.vue' /* webpackChunkName: "pages/tags" */))
const _42977761 = () => interopDefault(import('../pages/user.vue' /* webpackChunkName: "pages/user" */))
const _31ba8b0f = () => interopDefault(import('../pages/topic/tags.vue' /* webpackChunkName: "pages/topic/tags" */))
const _2a986ee2 = () => interopDefault(import('../pages/adviser/_id.vue' /* webpackChunkName: "pages/adviser/_id" */))
const _400c0afb = () => interopDefault(import('../pages/article/_id.vue' /* webpackChunkName: "pages/article/_id" */))
const _2552c922 = () => interopDefault(import('../pages/example/_id.vue' /* webpackChunkName: "pages/example/_id" */))
const _b4461b32 = () => interopDefault(import('../pages/letter/_id.vue' /* webpackChunkName: "pages/letter/_id" */))
const _01c68df6 = () => interopDefault(import('../pages/seminar/_id.vue' /* webpackChunkName: "pages/seminar/_id" */))
const _12f61e35 = () => interopDefault(import('../pages/serial/_id.vue' /* webpackChunkName: "pages/serial/_id" */))
const _44a63838 = () => interopDefault(import('../pages/topic/_name.vue' /* webpackChunkName: "pages/topic/_name" */))
const _62cd88a8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _a23d3e32,
    name: "about"
  }, {
    path: "/adviser",
    component: _3cccd867,
    name: "adviser"
  }, {
    path: "/contact",
    component: _7e89977a,
    name: "contact"
  }, {
    path: "/cookie-policy",
    component: _20ffe115,
    name: "cookie-policy"
  }, {
    path: "/emailchange",
    component: _4cca86a6,
    name: "emailchange"
  }, {
    path: "/error",
    component: _125f59e2,
    name: "error"
  }, {
    path: "/example",
    component: _22067347,
    name: "example"
  }, {
    path: "/letter",
    component: _5b5a2782,
    name: "letter"
  }, {
    path: "/login",
    component: _cf7e427a,
    name: "login"
  }, {
    path: "/logoff",
    component: _bae3f07e,
    name: "logoff"
  }, {
    path: "/logoffdone",
    component: _f7aa447a,
    name: "logoffdone"
  }, {
    path: "/passwordforget",
    component: _2bfa7bde,
    name: "passwordforget"
  }, {
    path: "/passwordreset",
    component: _5a164b6e,
    name: "passwordreset"
  }, {
    path: "/privacy",
    component: _5494ba3c,
    name: "privacy"
  }, {
    path: "/register",
    component: _b957c18e,
    name: "register"
  }, {
    path: "/registerdone",
    component: _06af0d8a,
    name: "registerdone"
  }, {
    path: "/rule",
    component: _7ffb2212,
    name: "rule"
  }, {
    path: "/seminar",
    component: _4b783fe4,
    name: "seminar"
  }, {
    path: "/serial",
    component: _5dd05c8d,
    name: "serial"
  }, {
    path: "/tags",
    component: _25fde8af,
    name: "tags"
  }, {
    path: "/user",
    component: _42977761,
    name: "user"
  }, {
    path: "/topic/tags",
    component: _31ba8b0f,
    name: "topic-tags"
  }, {
    path: "/adviser/:id",
    component: _2a986ee2,
    name: "adviser-id"
  }, {
    path: "/article/:id?",
    component: _400c0afb,
    name: "article-id"
  }, {
    path: "/example/:id",
    component: _2552c922,
    name: "example-id"
  }, {
    path: "/letter/:id",
    component: _b4461b32,
    name: "letter-id"
  }, {
    path: "/seminar/:id",
    component: _01c68df6,
    name: "seminar-id"
  }, {
    path: "/serial/:id",
    component: _12f61e35,
    name: "serial-id"
  }, {
    path: "/topic/:name?",
    component: _44a63838,
    name: "topic-name"
  }, {
    path: "/",
    component: _62cd88a8,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
